import React from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Sheet } from 'react-modal-sheet';
import { useDispatch } from 'react-redux';

import CloseIcon from '@/assets/icons/icon-close-white.svg';
import PurpleLines from '@/assets/images/purple-lines.svg';
import { useAppSelector } from '@/hooks/redux';
import useScrollTop from '@/hooks/useScrollTop';
import { useTheme } from '@/hooks/useTheme';
import { toggleMenuModal } from '@/redux/modal/modalSlice';
import { cn } from '@/utils/helper';

import StyledLink from './landing/home/StyledLink';

const navbarItems = [
  {
    text: 'Portfolio',
    link: '/login',
  },
  {
    text: 'Crypto',
    link: '/crypto-prices',
  },
  {
    text: 'NFT',
    link: '/nft-top-sales',
  },
  {
    text: 'Loans',
    link: '/nft-loans',
  },
];

const Navbar: React.FC<{ otherLinksHide?: boolean }> = ({ otherLinksHide }) => {
  const { mode } = useTheme();
  const feedScroll = useScrollTop(50);
  const dispatch = useDispatch();
  const { menuModal } = useAppSelector((state) => state.modal);
  const handleChange = () => dispatch(toggleMenuModal());
  const pathname = usePathname();
  return (
    <div className={cn('fixed top-0 w-full left-0 py-5 z-[23] sm:py-5 transition-all ease-linear duration-150', feedScroll ? '!bg-midnight-blue lg:!bg-jet-black' : '!bg-transparent')}>
      <div className='!bg-transparent w-full max-w-[1366px] pl-[17px] pr-[17px] ss:pr-5 xl:pl-[78px] xl:pr-[62px] m-auto flex items-center justify-between'>
        <Link href='/' className='hidden lg:flex h-[52px]'>
          <Image src={'/logo-dark.svg'} width={195} height={59} alt='netty-logo' priority quality={100} className='object-contain min-w-[195px] h-auto' />
        </Link>
        <Link href='/' className='block lg:hidden'>
          <Image src={'/logo-dark.svg'} width={165} height={34} alt='netty-logo' priority quality={100} className='object-contain ss:object-cover w-[150px] ss:w-max ss:min-w-[165px] h-[34px]' />
        </Link>
        {!otherLinksHide && (
          <>
            <nav className='hidden lg:flex items-center gap-[26px] !bg-transparent'>
              <div className='flex items-center gap-[19px] !bg-transparent'>
                {navbarItems.map(({ text, link }) => {
                  return (
                    <Link href={link} key={text} className={cn('font-medium text-xl/[31px]  hover:!text-primary', pathname === link ? '!text-primary' : 'text-white')}>
                      {text}
                    </Link>
                  );
                })}
              </div>
              <div className='flex items-center gap-[17px] !bg-transparent'>
                <StyledLink className='bg-dark-violet' href='/login'>
                  login
                </StyledLink>
                <StyledLink className='bg-magenta w-[136px]' href='/login'>
                  sign up
                </StyledLink>
              </div>
            </nav>
            <div className='flex items-center gap-[10px] lg:hidden !bg-transparent'>
              <Link href='/login' className='bg-dark-violet rounded-lg px-3 ss:px-5 py-2 font-bold ml-[7px] text-base/[19px] text-white'>
                login
              </Link>
              <Link href='/login' className='bg-magenta rounded-lg px-3 ss:px-5 py-2 font-bold ss:ml-[7px] text-base/[19px] whitespace-nowrap text-white'>
                sign up
              </Link>
            </div>
          </>
        )}
      </div>
      <Sheet isOpen={menuModal} onClose={handleChange} detent='content-height' className={`${mode}-sheet lg:hidden [&_.react-modal-sheet-container]:!max-h-screen [&_.react-modal-sheet-header]:bg-primary`}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <div className='h-screen bg-primary'>
              <button onClick={handleChange} className='absolute top-0 right-[26px] z-10'>
                <CloseIcon />
              </button>
              <PurpleLines className='absolute -top-[35px]' />
              <div className='flex flex-col justify-center items-center pt-[100px]'>
                <Image src={'/logo-dark.svg'} width={200} height={41} alt='netty-logo' priority quality={100} className='object-cover min-w-[200px] h-[41px]' />
                <div className='flex flex-col gap-[19px] items-center mt-[59px]'>
                  {navbarItems.map(({ text, link }) => {
                    return (
                      <Link href={link} key={text} onClick={handleChange} className={cn('font-medium text-white text-[27px]/[31px]  hover:!text-primary')}>
                        {text}
                      </Link>
                    );
                  })}
                </div>
                <div className='flex items-center gap-[17px] mt-[38px]'>
                  <StyledLink className='bg-dark-violet' href='/login'>
                    login
                  </StyledLink>
                  <StyledLink className='bg-magenta w-[136px]' href='/login'>
                    sign up
                  </StyledLink>
                </div>
              </div>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </div>
  );
};

export default Navbar;
